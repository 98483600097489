import GATSBY_COMPILED_MDX from "/usr/src/app/www/projects/fancylingo/index.mdx";
import * as React from "react";
import {graphql, Link} from "gatsby";
import {getImage} from "gatsby-plugin-image";
import {MDXProvider} from "@mdx-js/react";
import {Gist} from "mdx-embed";
import {Seo, Layout, Post, Analysis, ExternalLink, MobileVideo} from "@components";
const BlogPost = ({data: {mdx}, children}) => {
  return React.createElement(MDXProvider, {
    components: {
      a: props => {
        if (props.href.includes("https://")) {
          return React.createElement(ExternalLink, props);
        } else {
          return React.createElement(Link, {
            to: props.href
          }, props.children);
        }
      },
      MobileVideo,
      Gist
    }
  }, React.createElement(Layout, null, mdx.frontmatter.type === "blog" && React.createElement(Post, {
    date: mdx.frontmatter.date,
    title: mdx.frontmatter.title,
    image: mdx.frontmatter.hero_image,
    alt: mdx.frontmatter.hero_image_alt,
    imageLink: mdx.frontmatter.hero_image_credit_link,
    imageCredit: mdx.frontmatter.hero_image_credit_text,
    children: children
  }), mdx.frontmatter.type === "projects" && React.createElement(Analysis, {
    date: mdx.frontmatter.date,
    title: mdx.frontmatter.title,
    shortTitle: mdx.frontmatter.short_title,
    image: mdx.frontmatter.hero_image,
    alt: mdx.frontmatter.hero_image_alt,
    designCredit: mdx.frontmatter.design_credit_text,
    designLink: mdx.frontmatter.design_credit_link,
    githubLink: mdx.frontmatter.github_link,
    children: children
  })));
};
export const query = graphql`
  query ($id: String) {
    mdx(id: { eq: $id }) {
      frontmatter {
        title
        short_title
        description
        slug
        type
        date(formatString: "MMMM DD, YYYY")
        hero_image_alt
        hero_image_credit_link
        hero_image_credit_text
        hero_image {
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              formats: [AUTO, WEBP]
              width: 1000
              quality: 100
            )
          }
        }
        design_credit_text
        design_credit_link
        github_link
      }
    }
  }
`;
BlogPost
export default function GatsbyMDXWrapper(props) {
  return React.createElement(BlogPost, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
export const Head = ({data: {mdx}}) => React.createElement(Seo, {
  title: mdx.frontmatter.title,
  description: mdx.frontmatter.description,
  pathname: "/" + mdx.frontmatter.type + "/" + mdx.frontmatter.slug
}, React.createElement("script", {
  type: "application/ld+json"
}, `
        {
          "@context": "https://schema.org",
          "@type": "BlogPosting",
          "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": "https://jaybittner.com/${mdx.frontmatter.type}/${mdx.frontmatter.slug}"
          },
          "headline": "${mdx.frontmatter.title}",
          "image": "https://jaybittner.com${getImage(mdx.frontmatter.hero_image).images.fallback.src}",
          "author": {
            "@type": "Person",
            "name": "Jay Bittner",
            "url": "https://jaybittner.com"
          },  
          "publisher": {
            "@type": "Organization",
            "name": "Jay Bittner",
            "logo": {
              "@type": "ImageObject",
              "url": "https://jaybittner.com/logo.png"
            }
          },
          "datePublished": "${mdx.frontmatter.date}"
        }
      `));
